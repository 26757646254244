<template>
    <div class="case-card case-card--black case-card--more">
        <nuxt-link class="case-card__link" to="cases" aria-label="Смотреть все кейсы" />
        <div class="case-card__info">
	        <div
		        v-if="!viewport.isLessThan('desktopBig')"
		        class="f-h2 m-b-40-lg m-b-24-dp"
	        >
		        Проекты лидеров рынка
	        </div>

            <div class="case-card__links">
	            <div class="case-card__span f-lead m-b-12">Дизайн и брендинг</div>
	            <div class="case-card__span f-lead m-b-12">Интернет-магазины</div>
	            <div class="case-card__span f-lead m-b-12">Корпоративные сайты</div>
	            <div class="case-card__span f-lead m-b-12">Веб-сервисы и личные кабинеты</div>
	            <div class="case-card__span f-lead m-b-12">Внедрение Битрикс24</div>
	            <div class="case-card__span f-lead m-b-12">Мобильные приложения</div>
            </div>
            <Button
                class="case-card__btn"
                :btn-size="'medium'"
                :icon-name="'stroke-down'"
                @click="
                    $router.push({
                        name: 'cases',
                    })
                "
            >
                Смотреть все
            </Button>
        </div>
    </div>
</template>

<script setup lang="ts">
import Button from '~/components/ui/Button.vue';
const viewport = useViewport();
</script>

<style
    lang="scss"
    src="~/assets/scss/components/cases/case-card.scss"
    scoped
></style>
