<template>
	<Grid :grid-style="'cases'" :array="gridArray">
		<template #gridItem="{ item, index }">
            <CaseCard
                v-if="caseMore ? index < pageLimit : true"
                :caseCard="item"
            />
            <CaseMore v-else-if="caseMore" />
		</template>
	</Grid>

    <template v-if="pageLimit && btnMore && totalCount > pageLimit">
        <Pagination
            :page="page"
            :page-limit="pageLimit"
            :total-count="totalCount"
            @onBtnMoreClick="showMore"
            @onPagBtnClick="goToPage"
        />
    </template>
</template>

<script setup lang="ts">
import { useJsonld } from '#jsonld';
import Pagination from '~/components/ui/Pagination.vue';
import Grid from '~/components/ui/Grid.vue';
import CaseCard from '~/components/cases/CaseCard.vue';
import CaseLoader from '~/components/cases/CaseLoader.vue';
import CaseMore from '~/components/cases/CaseMore.vue';
import { useCasesStore } from '~/store';

const props = defineProps({
    pageLimit: { type: Number, required: false, default: 3, readonly: true },
    btnMore: { type: Boolean, default: false, required: false, readonly: true },
    caseMore: { type: Boolean, default: false, required: false, readonly: true },
    pop: { type: String || Boolean, default: '', required: false, readonly: true },
    random: { type: Boolean, default: false, required: false, readonly: true },
    caseId: { type: String, default: '', required: false, readonly: true },
});
const route = useRoute();
const { cases, totalCount, isLoading } = storeToRefs(useCasesStore());
const { fetchCases, fetchRandomCases, updateCases } = useCasesStore();
// const casesStore = useCasesStore();
const page = ref<number>(route.query['page'] ? Number(route.query['page']) : 1);

await useAsyncData(
    () => props.random
        ? fetchRandomCases(props.pageLimit, props.caseId)
        : fetchCases(page.value, props.pageLimit, props.pop)
);

const showMore = async () => {
    page.value++;
    await updateCases(page.value, props.pageLimit);
};

const addParamsToLocation = (params: { [key: string]: string }) => {
    history.pushState(
        {},
        '',
        route.path +
        '?' +
        Object.keys(params)
            .map(key => {
                return (
                    encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
                )
            })
            .join('&')
    )
}

const goToPage = async (data: number) => {
    page.value = data;
    addParamsToLocation({ page: page.value.toString() });
    await fetchCases(page.value, props.pageLimit, props.pop);
};

const viewport = useViewport();
const gridArray = computed(() => {
	if (cases.value) {
		if (props.caseMore) {
			return [...cases.value, {}];
		} else {
			return cases.value;
		}
	}

    // if (!casesStore.isLoading) {
    //     if (props.caseMore) {
    //         return [...casesStore.cases, {}];
    //     } else {
    //         return casesStore.cases;
    //     }
    // } else {
    //     if (viewport.isLessThan('tablet')) {
    //         return [0];
    //     } else if (viewport.isLessThan('desktopMedium')) {
    //         return [0, 1];
    //     }
    //     return [0, 1, 2];
    // }
});

// @ts-ignore
// useJsonld(() => {
// 	if (cases.value) {
// 		const arr: { [key: string]: string; }[] = [];
// 		cases.value.forEach((el) => {
// 			arr.push({
// 				'@context': 'https://schema.org',
// 				'@type': 'CreativeWork',
// 				'image': el.previewImg,
// 				'additionalType': el.type,
// 				'name': el.name,
// 			});
// 		});
// 		return arr;
// 	}
// });
</script>
