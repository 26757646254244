import { IS_DEV } from '~/utils/constants';
import { defineStore } from 'pinia';
import type { ICase, ICasesResponse } from '~/types';

export const useCasesStore = defineStore('cases', {
    state: () => ({
        cases: [] as ICase[] | null,
        mockCases: [
            {
                id: 'tufelka',
                code: 'tufelka',
                type: 'Интернет-магазин',
                name: '1000 и одна туфелька',
                previewImg: 'images/cases/case-prev-1.png',
                seo: { pictureTitle: '', pictureAlt: '' },
            },
            {
                id: 'techtrans',
                code: 'techtrans',
                type: 'Корпоративный сайт',
                name: 'Техтранс',
                previewImg: 'images/cases/case-prev-2.png',
                seo: { pictureTitle: '', pictureAlt: '' },
            },
            {
                id: 'reishi',
                code: 'reishi',
                type: 'Лендинг и личный кабинет',
                name: 'Рейши',
                previewImg: 'images/cases/case-prev-3.png',
                seo: { pictureTitle: '', pictureAlt: '' },
            },
            {
                id: 'redfab',
                code: 'redfab',
                type: 'Интерфейс 3D принтера',
                name: 'Redfab',
                previewImg: 'images/cases/case-prev-4.png',
                seo: { pictureTitle: '', pictureAlt: '' },
            },
            {
                id: 'anna-verdi',
                code: 'anna-verdi',
                type: 'Интернет-магазин',
                name: 'Anna Verdi',
                previewImg: 'images/cases/case-prev-5.png',
                seo: { pictureTitle: '', pictureAlt: '' },
            },
            {
                id: 'i-volution',
                code: 'i-volution',
                type: 'Корпоративный сайт',
                name: 'I-VOLUTION',
                previewImg: 'images/cases/case-prev-6.png',
                seo: { pictureTitle: '', pictureAlt: '' },
            },
            {
                id: 'schwarzberg',
                code: 'schwarzberg',
                type: 'Интернет-каталог продукции',
                name: 'Шварцберг',
                previewImg: 'images/cases/case-prev-7.png',
                seo: { pictureTitle: '', pictureAlt: '' },
                style: 'black',
            },
            {
                id: 'expert-agent',
                code: 'expert-agent',
                type: 'Каталог недвижимости',
                name: 'ExpertAgent',
                previewImg: 'images/cases/case-prev-8.png',
                seo: { pictureTitle: '', pictureAlt: '' },
            },
            {
                id: 'tufelka2',
                code: 'tufelka2',
                type: 'Интернет-магазин',
                name: '1000 и одна туфелька',
                previewImg: 'images/cases/case-prev-1.png',
                seo: { pictureTitle: '', pictureAlt: '' },
            },
            {
                id: 'techtrans2',
                code: 'techtrans2',
                type: 'Корпоративный сайт',
                name: 'Техтранс',
                previewImg: 'images/cases/case-prev-2.png',
                seo: { pictureTitle: '', pictureAlt: '' },
            },
            {
                id: 'reishi2',
                code: 'reishi2',
                type: 'Лендинг и личный кабинет',
                name: 'Рейши',
                previewImg: 'images/cases/case-prev-3.png',
                seo: { pictureTitle: '', pictureAlt: '' },
            },
        ] as ICase[],
        isLoading: false as boolean,
        totalCount: 0 as number,
    }),
    actions: {
        setCases(cases: ICase[] | null) {
            this.cases = cases;
        },
        addCases(cases: ICase[]) {
            this.cases?.push(...cases);
        },

        // fetch case
        async fetchCases(
            page: number,
            pageLimit: number,
            pop: string | undefined | boolean
        ): Promise<ICase[] | null> {
            if (IS_DEV) {
                this.setCases(
                    this.mockCases.slice(
                        pageLimit * (page - 1),
                        pageLimit * page
                    )
                );
                this.totalCount = this.mockCases.length;
                return this.cases;
            } else {
                this.isLoading = true;

                try {
                    const data: ICasesResponse = await $fetch(
                        `/api/cases?page=${page}&pageLimit=${pageLimit}&pop=${pop}`,
                    );

                    if (data) {
                        this.totalCount = data.total_count ? data.total_count : data.result.length;
                        this.setCases(data.result);
                        if (data.result) {
                            return data.result;
                        }
                        return null;
                    }

                    return null;
                    // if (!data) {
                    //     this.setCases(null);
                    //     throw createError({
                    //         statusCode: 404,
                    //         statusMessage: 'Page Not Found'
                    //     });
                    // }
                } catch (error) {
                    console.error('ERROR on fetchCases: ', error);
                    return null;
                } finally {
                    this.isLoading = false;
                }
            }
        },

        // fetch random cases
        async fetchRandomCases(
            limit: number = 3,
            id: string
        ): Promise<ICase[] | null> {
            if (IS_DEV) {
                this.setCases(
                    this.mockCases.slice(3)
                );
                this.totalCount = this.mockCases.length;
                return this.cases;
            } else {
                this.isLoading = true;

                try {
                    const data: ICasesResponse = await $fetch(
                        `/api/cases/randomCases?limit=${limit}&id=${id}`
                    );

                    if (data) {
                        this.totalCount = data.total_count ? data.total_count : data.result.length;
                        this.setCases(data.result);
                        if (data.result) {
                            return data.result;
                        }

                        return null;
                    }

                    return null;
                } catch (error) {
                    console.error('ERROR on fetchRandomCases: ', error);
                    return null;
                } finally {
                    this.isLoading = false;
                    // setTimeout(() => {
                    //     this.isLoading = false;
                    // }, 500);
                }
            }
        },

        // fetch case on button more
        async updateCases(
            page: number,
            pageLimit: number
        ): Promise<ICase[] | null> {
            if (IS_DEV) {
                this.addCases(
                    this.mockCases.slice(
                        pageLimit * (page - 1),
                        pageLimit * page
                    )
                );
                this.totalCount = this.mockCases.length;
                return this.cases;
            } else {
                try {
                    const data: ICasesResponse = await $fetch(
                        `/api/cases?page=${page}&pageLimit=${pageLimit}`,
                    );

                    if (data) {
                        this.totalCount = data.total_count ? data.total_count : data.result.length;
                        this.addCases(data.result);
                        if (data.result) {
                            return data.result;
                        }

                        return null;
                    }

                    return null;
                } catch (error) {
                    console.error('ERROR on updateCases: ', error);
                    return null;
                }
            }
        },
    },
});
