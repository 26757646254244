<template>
    <div class="pagination p-t-40 p-b-40">
        <!--		Math.ceil(totalCount / pageLimit)"-->
        <Button
            v-if="pageLimit * page < totalCount"
            class="m-b-16"
            :btn-size="'medium'"
            :btn-style="'secondary-night'"
            @click.prevent="onBtnMoreClick"
        >
            Показать еще
        </Button>

        <div class="pagination__list">
            <Grid class="m-b-16" :grid-style="'line'" :array="newTotalCount">
                <template #gridItem="{ item, index }">
                    <Button
                        :btn-style="
                            index + 1 === page
                                ? 'secondary-night'
                                : 'secondary-fill-night'
                        "
                        :btn-form="'round'"
                        @click.prevent="onPagBtnClick($event, index + 1)"
                    >
                        {{ item }}
                    </Button>
                </template>
            </Grid>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import Button from '~/components/ui/Button.vue';
import Grid from '~/components/ui/Grid.vue';

const props = defineProps({
    page: { type: Number, required: true, readonly: true },
    pageLimit: { type: Number, required: true, readonly: true },
    totalCount: { type: Number, required: true },
});

const emit = defineEmits(['onBtnMoreClick', 'onPagBtnClick']);
const onBtnMoreClick = (event: Event): void => {
    emit('onBtnMoreClick', event);
};

const onPagBtnClick = (event: Event, data: number): void => {
    emit('onPagBtnClick', data);
};

const newTotalCount = computed(() => {
    const num = Math.ceil(props.totalCount / props.pageLimit);
    return Array.from({ length: num }, (item, index) => index + 1);
});
</script>

<style
    lang="scss"
    src="~/assets/scss/components/ui/pagination.scss"
    scoped
></style>
