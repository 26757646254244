<template>
    <div
        :class="[
            'case-card',
            caseCard.style ? `case-card--${caseCard.style}` : '',
        ]"
        ref="card"
        :style="`transform: ${transform}`"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
        itemscope
        itemtype="https://schema.org/CreativeWork"
        vocab="https://schema.org/"
        typeof="CreativeWork"
    >
        <NuxtLink
            class="case-card__link"
            :to="`/cases/${caseCard.code}`"
            :title="caseCard.name"
            aria-label="Перейти в карточку кейса"
        ></NuxtLink>
        <NuxtImg
            v-if="caseCard.previewImg"
            class="case-card__bg lazy"
            :src="caseCard.previewImg"
            :alt="caseCard.seo.pictureAlt ? caseCard.seo.pictureAlt : caseCard.name"
            :title="caseCard.seo.pictureTitle ? caseCard.seo.pictureTitle : caseCard.name"
            width="614"
            height="620"
            densities="x1 x2"
            format="webp"
            loading="lazy"
            placeholder
            itemprop="image"
            property="image"
        />

        <div class="case-card__info">
            <div
	            class="case-card__type f-caption"
	            itemprop="additionalType"
                property="additionalType"
            >
                {{ caseCard.type }}
            </div>
            <div
	            class="case-card__name f-h4"
	            itemprop="name"
                property="name"
            >
                {{ caseCard.name }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useJsonld } from '#jsonld';
import type { ICase } from '~/types';

const props = defineProps({
    caseCard: {
        type: Object as PropType<ICase>,
        required: true,
        readonly: true,
    },
});

// @ts-ignore
useJsonld(() => ({
    '@context': 'https://schema.org',
    '@type': 'CreativeWork',
    'image': props.caseCard.previewImg,
    'additionalType': props.caseCard.type,
    'name': props.caseCard.name,
}));

const bounds = ref();
const card = ref();
const transform = ref<string>('none;');

function rotateToMouse(e: MouseEvent) {
    const mouseX = e.clientX;
    const mouseY = e.clientY;
    const leftX = mouseX - bounds.value.x;
    const topY = mouseY - bounds.value.y;
    const center = {
        x: leftX - bounds.value.width / 2,
        y: topY - bounds.value.height / 2,
    };
    const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

    setTimeout(() => {
        transform.value = `
            scale3d(1.02, 1.02, 1.02)
            rotate3d(
              ${center.y / 100},
              ${-center.x / 100},
              0,
              ${Math.log(distance) * 2}deg
            )
        `;
    }, 30);
}

function onMouseEnter() {
    if (process.client) {
        bounds.value = card.value.getBoundingClientRect();
        document.addEventListener('mousemove', rotateToMouse);
    }
}

function onMouseLeave() {
    if (process.client) {
        document.removeEventListener('mousemove', rotateToMouse);
        setTimeout(() => {
            transform.value = '';
        }, 30);
    }
}
</script>

<style
    lang="scss"
    src="~/assets/scss/components/cases/case-card.scss"
    scoped
></style>
